<template>
  <div class="rating-region-content content">
    <div class="content-search">
      <div class="content-search__select">
        <div class="content-search__select-item">
          <p>{{ $t('year') }}</p>
          <ui-select v-model="year" :options="getYears" @input="callApi" :disabled="!isAuth"/>
        </div>
      </div>
      <div class="content-search__icons">
        <img @click="isAuth ? visibleSearch = !visibleSearch : showAlert = true" :src="searchColor" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img @click="visibleAlertInSort"  :src="sortColor" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.value === sortBy?.value }]"
                  :key="key"
              >
                {{ item.name }}
                <img v-if="item.value === sortBy?.value" src="@/assets/svg/red-check.svg" alt="">
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <div class="content-input" v-if="visibleSearch">
      <ui-input search :placeholder="$t('search')" v-model="search" @input="updateRegionsApi"/>
    </div>
    <UiLoader v-model="loader" />
    <div class="region-content-body content-body" v-if="!loader">
      <div class="content-body__header">
        <p class="content-body__header-left">{{ $t('regions-open-length', {from: this.getRatingRegions.length, to: this.getRatingRegions.length}) }}</p>
        <div :class="{'content-body__header-right': true, 'content-body__header-right--disabled': !isAuth}">
          <p @click="$router.push('/region-indicators/filling-indicators')" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">{{ $t('fill-in-indicators') }}</p>
          <p  @click="isAuth ? downloadSummaryExcel() : showAlert = true"><img src="@/assets/svg/download-grey.svg" alt=""/>{{ $t('download-an-summary-excel-table') }}</p>
          <p @click="isAuth ? downloadExcel() : showAlert = true"><img src="@/assets/svg/download-grey.svg" alt=""/>{{ $t('download-an-detailed-excel-table') }}</p>
          <p @click=" isAuth ? visibleDescriptionAlert = !visibleDescriptionAlert : showAlert = true"><img src="@/assets/svg/attention-grey-outline.svg" alt=""/>{{ $t('help') }}</p>
        </div>
      </div>
      <div class="content-body__cards">
        <rating-region-card
            v-for="(item, key) in getRatingRegions"
            :key="key"
            :data="item"
            :year="year"
            :number="getCardNumber(item, key)"
            :sortBy="sortBy"
            @visibleAlert="visibleAlertInFocus"
        />
      </div>
      <div class="content-body__cards" v-if="!getRatingRegions.length">
        <p class="empty-list">{{ $t('data-not-published') }}</p>
      </div>
    </div>
    <ui-alert v-model="visibleDescriptionAlert" showClose>
      <h5 class="alert__title">{{ $t('description-of-indicators') }}</h5>
      <div class="alert__line"></div>
      <div class="alert__item">
        <p v-html="$t('application-description-link', {link: alertLink})"></p>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="visibleDescriptionAlert = !visibleDescriptionAlert">{{ $t('understood') }}</ui-button>
      </div>
    </ui-alert>
    <ui-alert v-model="showAlert">
      <h2 class="ui-alert__title">{{ $t('attention') }}</h2>
      <p class="ui-alert__text">{{ getTitle }}</p>
      <ui-button @click="$router.push('/login')" v-if="!isAuth">{{ $t('log-in') }}</ui-button>
      <ui-button @click="$router.push('/profile/edit-profile')" v-else>{{ $t('register') }}</ui-button>
    </ui-alert>
  </div>
</template>

<script>
import RatingRegionCard from "@/components/template/rating-region/RatingRegionCard.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiButton from "@/components/ui/UiButton.vue";
import UiAlert from "@/components/ui/UiAlert.vue";

export default {
  name: "RatingRegionContent",
  components: {
    UiAlert, UiButton,
    UiLoader,
    RatingRegionCard,
    UiInput: () => import("@/components/ui/UiInput"),
    UiMenu: () => import("@/components/ui/UiMenu"),
    UiSelect: () => import("@/components/ui/UiSelect"),
  },

  data() {
    return {
      visibleSearch: false,
      visibleSort: false,
      loader: false,
      sortBy: { name: this.$t('total-number-of-points'), value: 'Total' },
      sortData: [
        // { text: 'По названию региона', value: 'name' },
        { name: this.$t('by-environmental-component'), value: 'E' },
        { name: this.$t('by-social-component'), value: 'S' },
        { name: this.$t('by-governance-component'), value: 'G' },
        { name: this.$t('total-number-of-points'), value: 'Total' },
      ],
      search: '',
      year: null,
      visibleDescriptionAlert: false,
      alertLink: '',
      showAlert: false
    }
  },

  computed: {
    ...mapGetters(['getRatingRegions', 'getYears', 'getUser', 'getDocuments', 'isAuth']),

    sortColor() {
      return this.visibleSort ? require('@/assets/svg/search/red-sort.svg') : require('@/assets/svg/search/black-sort.svg')
    },

    searchColor() {
      return this.visibleSearch ? require('@/assets/svg/search/red-search.svg') : require('@/assets/svg/search/black-search.svg')
    },

    getTitle () {
      return !this.isAuth ? this.$t('log-in-to-view-data-by-region') : this.$t('sign-up-to-view-data-by-region')
    }
  },

  methods: {
    ...mapActions(['ratingRegions', 'years', 'saveExcel', 'documents', 'saveSummaryExcel']),
    ...mapMutations(['setRatingRegions', 'setYears']),
    changeSort(val) {
      this.visibleSort = false
      this.sortBy = val
      this.updateRegionsApi()
    },

    getCardNumber(data, key) {
      let isNum = 0
      data.rrTypes.forEach(el => {
        isNum+= el.value
      })

      if (isNum) {
        return key+1
      } else {
        return '-'
      }
    },

    callApi () {
      this.loader = true;
      this.ratingRegions({year: this.year.value, sort: this.sortBy.value}).then((res) => {
        this.loader = false;
        res = res.map(el => {
          el?.rrTypes.sort((a, b) => a.id-b.id)
          return el
        })
        this.setRatingRegions(res)
      })
    },

    updateRegionsApi () {
      this.loader = true;
      this.ratingRegions({
        year: this.year.value,
        search: this.search.length ? this.search : null,
        sort: this.sortBy?.value ? this.sortBy.value : null
      }).then((res) => {
        res = res.map(el => {
          el?.rrTypes.sort((a, b) => a.id-b.id)
          return el
        })
        this.setRatingRegions(res)
        this.loader = false;
      })
    },

    downloadExcel () {
      this.saveExcel({year: this.year.value}).then((res) => {
        const link = document.createElement('a');
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.file;
        link.download = res.file;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
    },
    downloadSummaryExcel() {
      this.saveSummaryExcel({year: this.year.value}).then((res) =>{
        const link = document.createElement('a');
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.file;
        link.download = res.file;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
    },
    visibleAlertInFocus () {
      !this.isAuth ? this.$emit('visibleAlert') : false
    },
    visibleAlertInSort () {
      if (!this.isAuth) {
        this.$emit('visibleAlert');
        setTimeout(() => {
          this.visibleSort = false;
          this.showAlert = true
        }, 50)
      }

    }


  },

  mounted () {
    this.loader = true
    this.setYears([])
    setTimeout(() => {
      this.years().then((res) => {
        let data = []
        if (this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin') {
          res.forEach(el => {
            let year = {
              name: el.id,
              value: el.id
            }
            data.push(year)
          })
          this.setYears(data)
          this.year = data[data.length-1]
          this.callApi()
        } else {
          if(res.some(el => el.isPublic)) {
            res.forEach(el => {
              if (el.isPublic) {
                let year = {
                  name: el.id,
                  value: el.id
                }
                data.push(year)
              }
            })
            this.setYears(data)
            this.year = data[data.length-1];
            this.callApi()
          } else {
            this.loader = false
          }
        }
      })
    }, 500)

    this.documents().then(() => {
      this.getDocuments.forEach(el => {
        if(el.name === 'Описание приложения') {
          this.alertLink = el.fileSrc.fileSrc
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;

  ::v-deep .filter__row {
    display: flex !important;

    .input-req {

      @media (min-width: 992px) {
        max-width: 207px;
      }
    }
    .first {
      @media (min-width: 992px) {
        max-width: 433px;
      }
    }
  }
}

.content-input {
  margin-bottom: 30px;
}

.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    border: none;
    padding-bottom: 0;
    gap: 20px;
  }

  &__icons {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
      color: red;
    }
  }

  &__select {
    width: 100%;
    margin-bottom: 24px;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;

      &__inner-text {
        font-family: "Gotham Pro Regular";
      }
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      max-width: 433px;
      background: #FFFFFF;
      padding: 20px;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }
    }
  }
}

.content-body {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &-left {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9A9A9A;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 20px;

      p {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #9A9A9A;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
      }
      &--disabled p {
        cursor: not-allowed;
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 30px;

    &.double {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    .empty-list {
      margin: 100px auto;
    }
  }
}
.load-more {
  margin-top: 20px;
}
.alert {
  &__title {
    color: #1B1A1F;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  &__line {
    background: #F5F5F5;
    width: 100%;
    height: 1px;
  }

  &__item {
    display: flex;

    p {
      color: #1B1A1F;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      ::v-deep a {
        color: #CE2121;
      }
    }
  }
}
::v-deep .ui-alert {
  background-color: #FFFFFF;
  gap: 20px;
  max-width: 400px;
  width: 100%;
}
</style>
